"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.searchFlag = exports.findFlagsByDialCode = exports.findFlagByDialCode = exports.findFlag = exports.getList = void 0;
var data_1 = require("./data");
var findFlag = function (code) {
    return data_1.default.find(function (flag) { return flag.code.toLowerCase() === code.toLowerCase(); });
};
exports.findFlag = findFlag;
var findFlagByDialCode = function (dialCode) {
    var _a;
    var filtersFlags = findFlagsByDialCode(dialCode);
    var preferredFlag = filtersFlags.find(function (flag) { return flag.dial_code === dialCode && flag.preferred; });
    if (preferredFlag) {
        return preferredFlag;
    }
    return (_a = filtersFlags[0]) !== null && _a !== void 0 ? _a : null;
};
exports.findFlagByDialCode = findFlagByDialCode;
var findFlagsByDialCode = function (dialCode) {
    return data_1.default.filter(function (flag) { return flag.dial_code === dialCode; });
};
exports.findFlagsByDialCode = findFlagsByDialCode;
var getList = function () {
    return data_1.default;
};
exports.getList = getList;
var searchFlag = function (keyword) {
    return data_1.default.filter(function (flag) {
        return (flag.code.toLowerCase().includes(keyword.toLowerCase()) ||
            flag.name.toLowerCase().includes(keyword.toLowerCase()) ||
            flag.dial_code.toLowerCase().includes(keyword.toLowerCase()));
    });
};
exports.searchFlag = searchFlag;
